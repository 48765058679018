import Vue from 'vue';
import Router from 'vue-router';
import { isEmpty } from 'lodash';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import TokenLogin from './views/TokenLogin.vue';
import auth from './auth';
import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      // This is for sso failure cases
      props: route => ({ externalMessage: route.query.message || '' }),
      meta: {
        loginLayout: true,
        hidden: true,
        title: 'Login',
        resetStack: true,
      },
      component: Login,
    },
    // token-login route receives JWT token from backend
    // and just forwards to another page
    {
      path: '/token-login/:token',
      name: 'tokenlogin',
      props: true,
      meta: {
        loginLayout: true,
        hidden: true,
        title: 'Token login',
        resetStack: true,
      },
      component: TokenLogin,
    },
    {
      path: '/',
      name: 'home',
      icon: 'home',
      meta: {
        title: 'Dashboard',
        resetStack: true,
      },
      component: Home,
    },
    {
      path: '/list/dimensional/',
      name: 'list-dimensional',
      props: true,
      meta: {
        title: 'Edit by field',
        resetStack: true,
      },
      component: () => import(/* webpackChunkName: "entitiesDimensional" */ './views/EntitiesDimensional.vue'),
    },
    {
      path: '/list/default/:type',
      name: 'list',
      props: true,
      meta: {
        title: 'Entity list',
        hidden: true,
        resetStack: true,
        subHeader: 'Edit by Entity',
      },
      menuItems: [], // this will be populated based on type definitions in MainMenu.vue
      component: () => import(/* webpackChunkName: "entities" */ './views/Entities.vue'),
    },
    { // "dirty-hack" for space separator to navigation tree
      path: '',
      meta: {
        subHeader: 'Localizations',
        childPermissions: [
          {
            target: 'localization',
            value: 'export',
          },
          {
            target: 'localization',
            value: 'import',
          },
        ],
      },
    },
    {
      path: '/localization/export',
      name: 'export',
      icon: 'arrow-up',
      meta: {
        title: 'Export',
        resetStack: true,
        permission: {
          target: 'localization',
          value: 'export',
        },
      },
      component: () => import('./views/localization/ExportLocalizations.vue'),
    },
    {
      path: '/localization/import',
      name: 'import',
      icon: 'arrow-down',
      meta: {
        title: 'Import',
        resetStack: true,
        permission: {
          target: 'localization',
          value: 'import',
        },
      },
      component: () => import('./views/localization/ImportLocalizations.vue'),
    },
    {
      path: '/edit/default/:type/:id/:locale/:mainLayer/:subLayer',
      name: 'edit',
      props: true,
      meta: {
        hidden: true,
        title: 'Edit entity',
      },
      component: () => import(/* webpackChunkName: "entityDefault" */ './views/EntityDefault.vue'),
    },
    {
      path: '/edit/locale/:type/:id/:mainLayer/:subLayer',
      name: 'edit-locales',
      props: true,
      meta: {
        hidden: true,
        title: 'Edit entity in locale-columns',
      },
      component: () => import(/* webpackChunkName: "entityLocales" */ './views/EntityLocales.vue'),
    },
    {
      path: '/edit/persp/:type/:id/:locale',
      name: 'edit-persp',
      props: true,
      meta: {
        hidden: true,
        title: 'Edit entity in perspective-columns',
      },
      component: () => import(/* webpackChunkName: "entityPerspectives" */ './views/EntityPerspectives.vue'),
    },
    {
      path: '/publishApproval/:type/:id',
      name: 'publish-approval',
      props: true,
      meta: {
        title: 'Publish Approval',
        hidden: true,
      },
      component: () => import(/* webpackChunkName: "publishApproval" */ './views/PublishApproval.vue'),
    },
    {
      path: '/admin/home',
      name: 'admin-home',
      meta: {
        title: 'Admin',
        hidden: true,
        resetStack: true,
      },
      component: () => import(/* webpackChunkName: "adminHome" */ './views/admin/AdminHome.vue'),
    },
    {
      path: '/admin/usergroup/:id',
      name: 'admin-usergroup',
      props: true,
      meta: {
        title: 'Admin - User Group',
        hidden: true,
      },
      component: () => import(/* webpackChunkName: "adminUserGroup" */ './views/admin/AdminUserGroup.vue'),
    },
    {
      path: '/admin/user/:username',
      name: 'admin-user',
      props: true,
      meta: {
        title: 'Admin - User',
        hidden: true,
      },
      component: () => import(/* webpackChunkName: "adminUser" */ './views/admin/AdminUser.vue'),
    },
    {
      path: '/admin/configuration/:id',
      name: 'admin-backend-configuration',
      props: true,
      meta: {
        title: 'Admin - Backend configuration',
        hidden: true,
      },
      component: () => import(/* webpackChunkName: "adminUser" */ './views/admin/AdminBackendConfiguration.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'tokenlogin') {
    if (auth.isAuthenticated()) {
      if (isEmpty(store.state.typeDefs) && isEmpty(store.state.user) && isEmpty(store.state.layeringSettings)) {
        store.dispatch('init').then(() => {
          next();
        }).catch(() => {
          next('/');
        });
      } else {
        next();
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.meta && to.meta.resetStack) {
    // if clicked from main menu, reset stack
    store.dispatch('replaceStack', to);
  } else if (store.getters.previousStackItem && store.getters.previousStackItem.fullPath === to.fullPath) {
    store.dispatch('removeLastFromStack');
  } else {
    store.dispatch('addToStack', to);
  }
});

export default router;
